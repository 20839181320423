<template>
  <VTable
    :head-row="USER_TABLE_HEAD"
    :touch-bar-list="touchBarAllUsers"
    url-action-query="search[Role]=3"
    url-set-rows="/users"
    url-action="/users/action"
    @link="goToSecurityUser"
  />
</template>

<script setup>
import VTable from '@/components/ui/table/VTable'

import { defineComponent } from 'vue'

import { touchBarAllUsers } from '@/utils/touchbar'
import { USER_TABLE_HEAD } from '@/utils/consts/User'
import { goToSecurityUser } from '@/utils/goRoute'

defineComponent({
  VTable
})
</script>
